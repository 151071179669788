// components/MermaidChart.js
import { use, useEffect, useMemo, useRef, useState } from "react";
import mermaid from "mermaid";
import { Terminal } from "lucide-react";
import { useTheme } from "../context/ThemeContext";
import { Button, Image, Result, Segmented, Space } from "antd";
import domtoimage from "dom-to-image";
import {
  CopyOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import copy from "copy-to-clipboard";
import { ShowMessage } from "./ShowMessage/ShowMessage";
import styles from "./mermaidChart.module.scss";
import { useRouter } from "next/router";
import { changeHistory, umlReChat } from "./api/mermaidChart";

const MermaidChart = (props: {
  chart: string;
  uuid: string;
  thread_id: string;
}) => {
  const router = useRouter();
  const { chart } = props;
  const chartRef = useRef<any>(null);
  const chartCodeRef = useRef<any>(null);
  const { theme } = useTheme();
  const [show, setShow] = useState("图表");
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [downloadLoading, setIsDownloadLoading] = useState(false);
  const [copyLoading, setCopyLoading] = useState(false);
  const [isChartRight, setIsChartRight] = useState(true);
  const [chartContent, setChartContent] = useState(chart);
  const [requeryLoading, setRequeryLoading] = useState(false);
  const responseData = useRef("");
  const requeryCount = useRef(0);
  useEffect(() => {
    if (props.thread_id === router.query.thread_id?.toString()) {
      mermaid.initialize({
        startOnLoad: true,
        theme: theme === "dark" ? "dark" : "default",
        gantt: {
          useWidth: chartRef.current?.offsetWidth, // Set the width of the chart to the width of the container.
          useMaxWidth: true, // When this flag is set, the width of the chart will be set to the maximum width of the container.
        },
      });
      mermaid.contentLoaded();
      setChartContent(chart);
      loadMermaid(chart);
    }
  }, [chart, theme]);
  const loadMermaid = (chart: string, reload?: boolean, limit = 2) => {
    mermaid
      .parse(chart)
      .then((parsedChart) => {
        setIsChartRight(true);
        setShow("图表");
        setRequeryLoading(false);
        setTimeout(() => {
          mermaid.contentLoaded();
        }, 100);
        if (reload) {
          changeHistory({
            history_id: props.uuid,
            text: responseData.current,
          });
        }
      })
      .catch((error) => {
        setIsChartRight(false);
        limit === 2 && setShow("Code");
        setRequeryLoading(true);
        umlReChat({
          thread_id: props.thread_id || "",
          chart: chart,
          errorMsg: error,
        }).then((res) => {
          responseData.current = res.data;
          const str = res.data.replace(/```mermaid\n/, "").replace(/\n```/, "");
          setChartContent(str);
          requeryCount.current += 1;
          if (requeryCount.current >= limit) {
            setShow("Code");
            setRequeryLoading(false);
          } else {
            loadMermaid(str, true, limit);
          }
        });
      });
  };
  const reLoad = (chart: string, reload?: boolean, limit = 1) => {
    mermaid
      .parse(chart)
      .then((parsedChart) => {
        setIsChartRight(true);
        setShow("图表");
        setRequeryLoading(false);
        setTimeout(() => {
          mermaid.contentLoaded();
        }, 100);
        if (reload) {
          changeHistory({
            history_id: props.uuid,
            text: responseData.current,
          });
        }
      })
      .catch((error) => {
        setIsChartRight(false);
        setRequeryLoading(true);
        umlReChat({
          thread_id: router.query.thread_id?.toString() || "",
          chart: chart,
          errorMsg: error,
        }).then((res) => {
          responseData.current = res.data;
          const str = res.data.replace(/```mermaid\n/, "").replace(/\n```/, "");
          setChartContent(str);
          if (requeryCount.current >= limit) {
            setRequeryLoading(false);
            setShow("Code");
          } else {
            requeryCount.current += 1;
            reLoad(str, true, limit);
          }
        });
      });
  };
  const downLoadSvgToPng = () => {
    if (downloadLoading) {
      return;
    }
    setIsDownloadLoading(true);
    const svg = chartRef.current;
    if (!svg) {
      return;
    }
    // 获取原始尺寸
    const originalWidth = svg.offsetWidth;
    const originalHeight = svg.offsetHeight;
    // 设置放大倍数
    const scaleFactor = 3;
    domtoimage
      .toPng(svg, {
        width: originalWidth * scaleFactor,
        height: originalHeight * scaleFactor,
        style: {
          transform: `scale(${scaleFactor})`,
          transformOrigin: "top left",
          width: `${originalWidth}px`,
          height: `${originalHeight}px`,
          backgroundColor: theme == "dark" ? "#282828" : "#F9F9F9",
        },
      })
      .then(function (dataUrl: string) {
        var link = document.createElement("a");
        link.download = `mermaid_${Date.now()}.png`;
        link.href = dataUrl;
        link.click();
        setIsDownloadLoading(false);
      })
      .catch(function (error: any) {
        setIsDownloadLoading(false);
      });
  };

  const copyToClipboard = () => {
    copy(chartCodeRef.current.innerText);
    ShowMessage.success("复制成功");
  };
  const copySVGAsPNGToClipboard = async () => {
    setCopyLoading(true);
    const base64Data = `data:image/svg+xml;base64,${Buffer?.from(
      chartRef.current?.innerHTML
    ).toString("base64")}`;
    try {
      const img = new window.Image();
      img.src = base64Data;

      img.onload = async function () {
        // Create a Canvas element
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const scaleFactor = 6;
        // Set canvas size to be scaled by scaleFactor
        canvas.width = img.width * scaleFactor;
        canvas.height = img.height * scaleFactor;

        if (context) {
          // Scale the context to draw at higher resolution
          context.fillStyle = theme == "dark" ? "#282828" : "#F9F9F9";
          context.fillRect(0, 0, canvas.width, canvas.height);
          context.scale(scaleFactor, scaleFactor);

          // Draw the Image onto the Canvas
          context.drawImage(img, 0, 0, img.width, img.height);

          // Convert the Canvas content to a PNG Blob
          canvas.toBlob(async (blob) => {
            if (blob) {
              // Write the PNG Blob to the clipboard
              await navigator.clipboard.write([
                new ClipboardItem({
                  "image/png": blob,
                }),
              ]);
              ShowMessage.success("复制成功");
              setCopyLoading(false);
            }
          }, "image/png");
        }
      };

      img.onerror = function () {
        setCopyLoading(false);
      };
    } catch (err) {
      setCopyLoading(false);
    }
  };
  const imgPreview = useMemo(() => {
    return isPreviewVisible ? (
      <div className={styles.imgPreview} style={{ display: "none" }}>
        <Image
          style={{ display: "none" }}
          src={`data:image/svg+xml;base64,${Buffer?.from(
            chartRef.current?.innerHTML
          ).toString("base64")}`}
          preview={{
            visible: isPreviewVisible,
            className: styles.imgPreview,
            onVisibleChange: (value) => {
              setIsPreviewVisible(value);
            },
          }}
          alt="预览"
        />
      </div>
    ) : null;
  }, [isPreviewVisible]);
  const mermaidDiv = useMemo(() => {
    return (
      <div
        className={`not-prose rounded-md  border-2  ${
          theme === "dark" ? "border-black" : "border-gray"
        }`}
      >
        <div
          className={`flex h-12 items-center justify-between px-4 ${
            theme == "light" ? " bg-zinc-100 dark:bg-zinc-900" : "bg-zinc-900"
          }`}
        >
          <div className="flex items-center gap-2">
            <Terminal size={16} />
            <p
              className={`text-sm ${
                theme == "light" ? "text-zinc-600 dark:text-zinc-400" : ""
              }`}
            >
              mermaid
            </p>
          </div>
          <Segmented<string>
            options={["Code", "图表"]}
            value={show}
            onChange={(value) => {
              setShow(value); // string
            }}
          />
          {show === "Code" ? (
            <div onClick={copyToClipboard} style={{ cursor: "pointer" }}>
              <CopyOutlined />
            </div>
          ) : (
            <Space size="large">
              <div
                onClick={copySVGAsPNGToClipboard}
                style={{ cursor: "pointer" }}
              >
                {!copyLoading ? <CopyOutlined /> : <LoadingOutlined />}
              </div>
              <div onClick={downLoadSvgToPng} style={{ cursor: "pointer" }}>
                {!downloadLoading ? <DownloadOutlined /> : <LoadingOutlined />}
              </div>
            </Space>
          )}
        </div>
        <div className={`overflow-x-auto`}>
          <div
            className="p-4"
            ref={chartCodeRef}
            style={{
              backgroundColor: theme == "light" ? "#D2D2D2" : "#282828",
              display: show === "Code" ? "block" : "none",
            }}
          >
            {chartContent}
          </div>
        </div>
        {isChartRight ? (
          <div
            ref={chartRef}
            className="mermaid"
            style={{
              display: show === "Code" ? "none" : "flex",
              justifyContent: "center",
              cursor: "pointer",
              width: "100%",
              maxWidth: "100%",
            }}
            onClick={() => {
              setIsPreviewVisible(true);
            }}
            key={chartContent}
          >
            {chartContent}
          </div>
        ) : (
          <div
            style={{
              display: show === "Code" ? "none" : "flex",
              justifyContent: "center",
            }}
          >
            <Result
              status="warning"
              title="Mermaid 语法解析出错，请重新对话或者点击重试"
              extra={
                <Button
                  color="primary"
                  variant="outlined"
                  loading={requeryLoading}
                  onClick={() => {
                    requeryCount.current = 0;
                    reLoad(chartContent, false, 1);
                  }}
                >
                  {requeryLoading? "重新生成中" : "重新生成"}
                </Button>
              }
            />
          </div>
        )}
        {imgPreview}
      </div>
    );
  }, [
    chartContent,
    imgPreview,
    show,
    theme,
    downloadLoading,
    copyLoading,
    isChartRight,
    requeryLoading,
  ]);
  return mermaidDiv;
};

export default MermaidChart;
