import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Carousel, Modal } from "antd";
import styles from "./guide.module.scss";
import classNames from "classnames";

const Guide = (props: {
  onCancel: () => void;
}, ref: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useImperativeHandle(ref, () => {
    return {
      show: () => {
        setIsModalOpen(true);
      },
      close: handleCancel,
    };
  });

  useEffect(() => {}, []);

  const handleCancel = () => {
    setIsModalOpen(false);
    props.onCancel();
  };
  return (
    <Modal
      title="使用说明"
      open={isModalOpen}
      maskClosable={true}
      footer={null}
      centered
      width={"70vw"}
      onCancel={handleCancel}
    >
      <Carousel
        arrows
        infinite={true}
        autoplaySpeed={8000}
        autoplay
        className={styles.carousel}
      >
        <div className={classNames(styles.icon, styles.uml)}></div>
        <div className={classNames(styles.icon, styles.light_theme)}></div>
        <div className={classNames(styles.icon, styles.copy)}></div>
        <div className={classNames(styles.icon, styles.mb)}></div>
      </Carousel>
    </Modal>
  );
};
export default forwardRef(Guide);
