// components/TypingEffect.js
import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from "react";
import ReactMarkdown, { Options } from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import "highlight.js/styles/github.css"; // 导入代码高亮的CSS样式
import "highlight.js/styles/monokai-sublime.css"; // 导入代码高亮的CSS样式
import "highlight.js/styles/atom-one-dark.css"; // 导入代码高亮的CSS样式
import "highlight.js/styles/atom-one-light.css"; // 导入代码高亮的CSS样式
import hljs from "highlight.js";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css"; // KaTeX 样式
import { useTheme } from "../context/ThemeContext";
import rehypeRaw from "rehype-raw";
import plantumlEncoder from "plantuml-encoder";
import { Image } from "antd";
import { useRouter } from "next/router";
import CodeBlock from "./CodeBlock/CodeBlock";
import MermaidChart from "./MermaidChart";

const TypingEffect = (
  props: {
    text: string;
    pending?: boolean;
    stop?: boolean;
    goToBottom?: () => void;
    uuid?: string;
    thread_id?: string;
  },
  ref: any
) => {
  const {
    text = "",
    pending = false,
    goToBottom = () => {},
    uuid,
    thread_id,
  } = props;
  const { theme } = useTheme();
  const router = useRouter();
  const isRendered = useRef(false);
  const [imgLoadErr, setImgLoadErr] = useState(false);
  const umlRegex =
    /@start(uml|json|yaml|bnf|regex|salt|ditaa|gantt|chronology|mindmap|wbs|chen)[\s\S]*?@end\1/g;
  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = theme === "light" ? "/github.css" : "/atom-one-dark.css"; // 你可以根据需要选择其他 dark 主题
    document.head.appendChild(link);
    hljs.highlightAll();
    return () => {
      document.head.removeChild(link);
    };
  }, [theme]);
  useEffect(() => {
    // 每次 messages 更新时，自动滚动到底部
    goToBottom();
  }, [text]);
  useEffect(() => {
    if (isRendered.current) {
      setTimeout(() => {
        goToBottom();
      }, 400);
    }
  }, [isRendered]);

  useImperativeHandle(ref, () => {
    return {};
  });
  const LinkRenderer = (data: any) => {
    const { href, children, ...args } = data;
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" {...args}>
        {children}
      </a>
    );
  };
  const ImgRenderer = (data: any) => {
    const { src, children, ...args } = data;
    return <Image src={src} preview={!pending} alt="" />;
  };
  const ImageRender = (url: any) => {
    const urlList = JSON.parse(localStorage.getItem("sUrl") || "[]");
    if (!urlList?.includes(url)) {
      const fetchImage = async () => {
        try {
          isRendered.current = false;
          const response = await fetch(url, { method: "HEAD" });
          if (response.status !== 200) {
            setImgLoadErr(true);
          } else {
            localStorage.setItem("sUrl", JSON.stringify([...urlList, url]));
            isRendered.current = true;
          }
        } catch (error) {
          setImgLoadErr(true);
        }
      };
      fetchImage();
    }
    // 使用useMemo来记忆计算结果
    const memoizedValue = useMemo(
      () => (
        <Image
          key={url}
          src={url}
          preview={!pending}
          onError={() => {
            setImgLoadErr(true);
          }}
          alt=""
        />
      ),
      [url]
    );

    return memoizedValue;
  };
  const MermaidRenderer = (children: any, uuid: any, thread_id: any) => {
    const Mermaid = useMemo(
      () => (
        <MermaidChart
          chart={children}
          uuid={uuid}
          thread_id={thread_id}
        ></MermaidChart>
      ),
      [children, thread_id, uuid]
    );
    return Mermaid;
  };
  const CodeBlockRenderer = (
    children: any,
    node?: any,
    arg?: any,
    simple?: boolean
  ) => {
    const Mermaid = useMemo(
      () => (
        <CodeBlock node={node} simple={simple} arg={arg}>
          {children}
        </CodeBlock>
      ),
      [node, simple, arg, children]
    );
    return Mermaid;
  };
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, [remarkMath, { singleDollarTextMath: true }]]}
      rehypePlugins={[
        rehypeRaw,
        rehypeKatex,
        // @ts-ignore
        [rehypeHighlight, { ignoreMissing: "true" }],
      ]}
      components={{
        pre: ({ children }) => <pre className="not-prose">{children}</pre>,
        a: LinkRenderer,
        img: ImgRenderer,
        code: (data: any) => {
          const { node, className, children, ...props } = data;
          const match = /language-(\w+)/.exec(className || "");
          if (match?.length) {
            const isPlantUml =
              match.includes("language-plantuml") ||
              match.includes("plantuml") ||
              match.includes("plaintext") ||
              match.includes("language-plaintext");
            const isMermaid =
              match.includes("language-mermaid") || match.includes("mermaid");
            const umlMatches = umlRegex.test(children);
            if (isPlantUml && umlMatches && !imgLoadErr && !pending) {
              const match = children.match(umlRegex);
              const encoded = plantumlEncoder.encode(match?.[0] || "");
              const url = `https://eagle.capitalren.com/plant-uml/png/${encoded}`;
              return ImageRender(url);
            }
            if (isMermaid && !pending) {
              return MermaidRenderer(children, uuid, thread_id);
            }
            return CodeBlockRenderer(children, node, { ...props }, false);
          } else {
            return CodeBlockRenderer(children, node, { ...props }, true);
          }
        },
      }}
      className={`max-w-full overflow-x-auto prose ${
        theme === "light" ? "prose-zinc" : "prose-invert"
      }`}
    >
      {text}
    </ReactMarkdown>
  );
};

export default forwardRef(TypingEffect);
