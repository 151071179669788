import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import UpLoad from "../UpLoad/UpLoad";
import LoadingIcon from "../Icon/LoadingIcon";
import styles from "../../styles/chat.module.scss";
import { useTheme } from "../../context/ThemeContext";
import { CloseCircleFilled } from "@ant-design/icons";

// eslint-disable-next-line react/display-name
const ChatTextArea = forwardRef(
  (
    props: {
      fileName: string;
      onUpload: (e: any) => void;
      handleInputChange: (e: { target: any }) => void;
      keyupFun: (e: any) => void;
      createChat: () => void;
      cancleFn: () => void;
      stop: boolean;
      upLoading: boolean;
      showChatTip: boolean;
      onClick: (e: any) => void;
      deleteFile: () => void;
      upLoadDisabled: boolean;
    },
    ref: any
  ) => {
    const {
      fileName,
      onUpload,
      stop,
      upLoading,
      showChatTip,
      handleInputChange,
      keyupFun,
      createChat,
      cancleFn,
      onClick,
      deleteFile,
      upLoadDisabled
    } = props;
    const { theme } = useTheme();
    const [value, setValue] = useState("");
    useEffect(() => {
      const textarea = document.querySelector("textarea");
      if (!value && textarea) {
        textarea.style.height = "24px"; // 恢复到初始高度
      }
    }, [value]);
    const onChange = (e: any) => {
      const textarea = e.target;
      setValue(textarea.value);
      textarea.style.height = "auto"; // 先重置高度
      textarea.style.height = `${textarea.scrollHeight}px`; // 根据内容调整高度
      if (!textarea.value) {
        textarea.style.height = "24px"; // 恢复到初始高度
      }
      handleInputChange(e);
    };
    // 使用 useImperativeHandle 来定义 ref 可以访问的方法
    useImperativeHandle(ref, () => ({
      setTextAreaValue: (test: string) => {
        setValue(test);
      },
    }));
    return (
      <div className={styles.chatpdfArea}>
        <UpLoad
          disabled={upLoadDisabled}
          onChange={(e) => {
            e.preventDefault();
            onUpload(e.target.files);
          }}
          onClick={onClick}
        ></UpLoad>
        <div className={styles.areaBox}>
          {/* 提示 */}
          {/* {showChatTip && (
            <div className={styles.chatTip}>
              <div className={styles.chatTipText}>
                你好，Eagle小助手提醒您：建议您把github中相关的链接放进来，可以更好的使用Eagl强大功能哦
              </div>
            </div>
          )} */}
          <div className={styles.input}>
            {fileName && (
              <div className={styles.file}>
                <div className={styles.upLoadFileBox}>
                  <div className={styles.fileBg}>
                    {upLoading && <LoadingIcon style={{ margin: "0 auto" }} />}
                  </div>
                  <span className={styles.fileName}>{fileName}</span>
                  {!upLoading && (
                    <div className={styles.closeBtn} onClick={deleteFile}>
                      <CloseCircleFilled />
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className={styles.chatTextAreaBox} onClick={onClick}>
              <div className={styles.textareaBox}>
                <textarea
                  wrap="off"
                  disabled={stop}
                  style={{
                    color: theme === "dark" ? "#FFFFFF" : "#000000",
                  }}
                  rows={1}
                  className={styles.textarea}
                  placeholder="请在此输入您需要解读的内容"
                  value={value}
                  onChange={onChange}
                  onKeyDown={keyupFun}
                ></textarea>
              </div>
              {!stop && (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                  className={upLoading ? styles.sendDisabled : styles.send}
                  onClick={createChat}
                  src={
                    theme === "light" ? "chat/send_light.png" : "chat/send.png"
                  }
                  alt=""
                />
              )}
              {/* @ts-ignore */}
              {stop && (
                <svg
                  onClick={cancleFn}
                  className={styles.svg}
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="10686"
                  width="48"
                  height="48"
                >
                  <path
                    d="M510.407736 960.414823c-60.290131 0-118.797663-11.81714-173.898609-35.122939-53.199643-22.50148-100.970646-54.706973-141.983735-95.720062-41.013089-41.013089-73.218582-88.784092-95.720062-141.983735-23.305799-55.101969-35.122939-113.608477-35.122939-173.898609 0-60.290131 11.81714-118.797663 35.122939-173.898609 22.50148-53.200666 54.706973-100.970646 95.720062-141.983735 41.013089-41.014112 88.784092-73.218582 141.983735-95.721085 55.101969-23.305799 113.608477-35.122939 173.898609-35.122939s118.797663 11.81714 173.898609 35.122939c53.199643 22.502503 100.970646 54.706973 141.983735 95.721085 41.013089 41.013089 73.218582 88.784092 95.720062 141.983735 23.305799 55.100946 35.122939 113.608477 35.122939 173.898609 0 60.290131-11.81714 118.797663-35.122939 173.898609-22.50148 53.199643-54.706973 100.970646-95.720062 141.983735s-88.784092 73.218582-141.983735 95.720062C629.205399 948.597683 570.697867 960.414823 510.407736 960.414823zM510.407736 130.408095c-102.377692 0-198.627826 39.868009-271.0206 112.260783-72.39175 72.392774-112.260783 168.642908-112.260783 271.0206s39.868009 198.627826 112.260783 271.0206c72.392774 72.392774 168.642908 112.260783 271.0206 112.260783s198.62885-39.868009 271.0206-112.260783c72.392774-72.392774 112.260783-168.642908 112.260783-271.0206s-39.868009-198.627826-112.260783-271.0206C709.036585 170.276105 612.786451 130.408095 510.407736 130.408095z"
                    fill="var(--primary-color)"
                    p-id="10687"
                  ></path>
                  <path
                    d="M348.904435 353.853144l323.007625 0 0 319.671647-323.007625 0 0-319.671647Z"
                    fill="var(--primary-color)"
                    p-id="10688"
                  ></path>
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default ChatTextArea;
