import http from "../../http";

export const umlReChat = (params: {
  thread_id: string;
  chart: string;
  errorMsg: string;
}) => {
  return http.post(`/api/v1/chat/uml`, {
    thread_id: params.thread_id,
    content: params.chart + "### " + params.errorMsg,
  });
};

export const changeHistory = (params: {
  history_id: string;
  text: string;
}) => {
  return http.put(`/api/v1/chat/chat_history`, {
    history_id: params.history_id,
    text: params.text,
  });
};
